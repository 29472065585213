export default (BaseClass) => class extends BaseClass {
  connect () {
    super.connect()

    this.bindReconnect = this.reconnect.bind(this)
    window.addEventListener('turbo:morph', this.bindReconnect)
  }

  reconnect () {
    this.disconnect()
    this.connect()
  }

  disconnect () {
    window.removeEventListener('turbo:morph', this.bindReconnect)

    super.disconnect()
  }
}
