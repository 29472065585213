import c0 from './range_controller';
import c1 from './nested_form_controller';
import c2 from './autosubmit_controller';
import c3 from './airbrake_controller';
import c4 from './autocomplete_controller';
import c5 from './forms/dependend_field_visibility_controller';
import c6 from './richtext_controller';
import c7 from './password_check_controller';
import c8 from './scroll_to_controller';
import c9 from './image_upload_controller';
import c10 from './duo_range_controller';
import c11 from './clipboard_controller';
import c12 from './screenshot_controller';
import c13 from './popup_controller';
import c14 from './select_controller';
import c15 from './rich_text_area_controller';
import c16 from './sortable_controller';
export const definitions = [
	{identifier: 'range', controllerConstructor: c0},
	{identifier: 'nested-form', controllerConstructor: c1},
	{identifier: 'autosubmit', controllerConstructor: c2},
	{identifier: 'airbrake', controllerConstructor: c3},
	{identifier: 'autocomplete', controllerConstructor: c4},
	{identifier: 'forms--dependend-field-visibility', controllerConstructor: c5},
	{identifier: 'richtext', controllerConstructor: c6},
	{identifier: 'password-check', controllerConstructor: c7},
	{identifier: 'scroll-to', controllerConstructor: c8},
	{identifier: 'image-upload', controllerConstructor: c9},
	{identifier: 'duo-range', controllerConstructor: c10},
	{identifier: 'clipboard', controllerConstructor: c11},
	{identifier: 'screenshot', controllerConstructor: c12},
	{identifier: 'popup', controllerConstructor: c13},
	{identifier: 'select', controllerConstructor: c14},
	{identifier: 'rich-text-area', controllerConstructor: c15},
	{identifier: 'sortable', controllerConstructor: c16},
];
