import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

import { XYChartController } from './xy_chart_component_controller'
import { BaseChartController } from './base_chart_component_controller'

export default class extends XYChartController {
  static values = merge(cloneDeep(BaseChartController.values), {
    xAxisCategories: Array
  })

  seriesType () {
    return 'bar'
  }

  axisTypes () {
    return merge(super.axisTypes(), {
      category: {
        type: 'category',
        data: this.xAxisCategories,
        axisLabel: {
          interval: 0,
          overflow: 'break',
          showMinLabel: true,
          showMaxLabel: true,
          hideOverlap: false
        }
      }
    })
  }
}
