import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

import {
  numberToArea,
  numberToCurrency,
  numberToCurrencyPerArea,
  numberToDays,
  numberToHuman,
  numberToPercentage
} from '../../javascript/helpers/number_helper'
import { BaseChartController, colors, defaultBoldTextStyle, defaultTextStyle } from './base_chart_component_controller'

export class XYChartController extends BaseChartController {
  static values = merge(cloneDeep(BaseChartController.values), {
    axisLabel: String,
    xAxisType: String,
    xAxisName: String,
    xAxisStartZero: { type: Boolean, default: false },
    yAxisType: String,
    yAxisName: String,
    yAxisStartZero: { type: Boolean, default: false },
    tooltipAxisType: String,
    tooltipFormatter: { type: Object, default: {} },
    tooltipValueFormatter: { type: String, default: 'yAxis' },
    legendType: String,
    numberOfForecasts: { type: Number, default: 0 }
  })

  defaultSeriesStyle = {
    symbol: 'circle',
    symbolSize: 8,
    lineStyle: {
      width: 2
    }
  }

  transformToDataset (series) {
    return series.map((s) => {
      return {
        dimension: ['x', 'y'],
        source: s.data
      }
    })
  }

  transformToSeries (series, options, offset = 0) {
    return series.map((s, index) => {
      return merge(cloneDeep(this.defaultSeriesStyle), cloneDeep(options), {
        name: s.title,
        datasetIndex: index + offset,
        lineStyle: s.lineStyle,
        markArea: markArea(s, this.numberOfForecastsValue)
      })
    })
  }

  seriesType () {
    return 'none'
  }

  options () {
    return merge(super.options(), {
      color: this.colors,
      xAxis: this.xAxisOptions(),
      yAxis: this.yAxisOptions(),
      legend: this.legendOptions(),
      tooltip: this.tooltipOptions(),
      dataset: this.transformToDataset(this.seriesValue),
      series: this.transformToSeries(this.seriesValue, {
        type: this.seriesType()
      }),
      grid: {
        left: 2,
        top: this.titleValue !== '' ? 40 : 10,
        bottom: this.gridBottomValue(),
        right: this.legendTypeValue === 'vertical' ? 200 : 30,
        containLabel: true
      }
    })
  }

  gridBottomValue () {
    switch (this.legendTypeValue) {
      case 'horizontal':
      case 'horizontal_two_lines':
        return 50
      default:
        return 0
    }
  }

  tooltipOptions () {
    let type
    switch (this.tooltipValueFormatterValue) {
      case 'yAxis':
        type = this.yAxisTypeValue
        break
      case 'xAxis':
        type = this.xAxisTypeValue
        break
      default:
        type = this.tooltipValueFormatterValue
    }

    return {
      trigger: 'axis',
      axisPointer: tooltipAxisTypes(this.localeValue, this.tooltipAxisTypeValue),
      valueFormatter: tooltipValueFormatter(this.localeValue, type),
      formatter: this.tooltipFormatter()
    }
  }

  legendOptions () {
    return legendTypes[this.legendTypeValue]
  }

  xAxisOptions () {
    return merge({
      name: this.xAxisNameValue,
      nameLocation: 'middle',
      nameGap: 30,
      scale: true,
      min: this.xAxisStartZeroValue ? 0 : null,
      axisLabel: cloneDeep(defaultAxisLabel),
      axisLine: cloneDeep(defaultAxisLine),
      axisTick: cloneDeep(defaultXAxisTick)
    }, this.axisTypes()[this.xAxisTypeValue])
  }

  yAxisOptions () {
    return merge({
      name: this.yAxisNameValue,
      scale: true,
      min: this.yAxisStartZeroValue ? 0 : null,
      nameLocation: 'end',
      nameTextStyle: defaultBoldTextStyle,
      axisLabel: cloneDeep(defaultAxisLabel),
      axisLine: cloneDeep(defaultAxisLine)
    }, this.axisTypes()[this.yAxisTypeValue])
  }

  axisTypes () {
    return {
      area: {
        type: 'value',
        minInterval: 1,
        axisLabel: { formatter: (value) => numberToArea(value, this.localeValue, { maximumFractionDigits: 0 }) }
      },
      value: {
        type: 'value',
        minInterval: 1,
        axisLabel: { formatter: (value) => numberToHuman(value, this.localeValue) }
      },
      days: {
        type: 'value',
        minInterval: 1,
        axisLabel: { formatter: (value) => numberToDays(value, this.localeValue, { maximumFractionDigits: 0 }) }
      },
      currency: {
        type: 'value',
        minInterval: 1,
        axisLabel: { formatter: (value) => numberToCurrency(value, this.localeValue, { maximumFractionDigits: 0 }) }
      },
      currency_by_area: {
        type: 'value',
        minInterval: 1,
        axisLabel: { formatter: (value) => numberToCurrencyPerArea(value, this.localeValue, { maximumFractionDigits: 0 }) }
      },
      percentage: {
        type: 'value',
        axisLabel: { formatter: (value) => numberToPercentage(value, this.localeValue, { maximumFractionDigits: 0 }) }
      },
      datetime: {
        type: 'time',
        minInterval: 86400000
      },
      date: {
        type: 'time',
        axisLabel: { formatter: '{dd}.{MM}.{yyyy}' },
        minInterval: 86400000
      },
      monthly: {
        type: 'time',
        axisLabel: { formatter: '{MMM} {yyyy}' },
        minInterval: 86400000
      },
      quarterly: {
        type: 'time',
        axisLabel: { formatter: 'Q{Q}\' {yyyy}' },
        minInterval: 86400000
      },
      yearly: {
        type: 'time',
        axisLabel: { formatter: '{yyyy}' },
        minInterval: 86400000
      }
    }
  }

  tooltipFormatter () {
    return undefined
  }
}

export function tooltipValueFormatter (locale, type, options = undefined) {
  return {
    value: (value) => numberToHuman(value, locale),
    days: (value) => numberToDays(value, locale),
    currency: (value) => numberToCurrency(value, locale),
    currency_by_area: (value) => numberToCurrencyPerArea(value, locale),
    percentage: (value) => numberToPercentage(value, locale, options)
  }[type]
}

function tooltipAxisTypes (locale, type) {
  return {
    date: {
      label: {
        formatter: ({ value }) => {
          return new Date(value).toLocaleDateString(locale)
        }
      }
    },
    monthly: {
      label: {
        formatter: ({ value }) => {
          const date = new Date(value)
          return `${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`
        }
      }
    },
    quarterly: {
      label: {
        formatter: ({ value }) => {
          const date = new Date(value)
          const quarter = Math.floor(date.getMonth() / 3) + 1
          return `Q${quarter}' ${date.getFullYear()}`
        }
      }
    },
    yearly: {
      label: {
        formatter: ({ value }) => {
          const date = new Date(value)
          return `${date.getFullYear()}`
        }
      }
    }
  }[type]
}

function markArea (s, numberOfForecasts) {
  if (!numberOfForecasts) return

  return {
    silent: true,
    data: [[
      { xAxis: s.data.at(-1 * numberOfForecasts).x },
      { xAxis: s.data.at(-1).x }
    ]],
    itemStyle: {
      color: colors.base[300]
    }
  }
}

const legendTypes = {
  none: {
    show: false
  },
  vertical: {
    orient: 'vertical',
    top: 'center',
    right: 0,
    itemHeight: 14,
    textStyle: defaultTextStyle
  },
  horizontal: {
    orient: 'horizontal',
    bottom: 0,
    itemHeight: 14,
    textStyle: defaultTextStyle
  },
  horizontal_two_lines: {
    orient: 'vertical',
    bottom: 0,
    itemHeight: 14,
    textStyle: defaultTextStyle,
    height: 40
  }
}

const defaultAxisLabel = {
  fontSize: 14,
  margin: 12,
  showMinLabel: true,
  showMaxLabel: true,
  hideOverlap: true
}

const defaultLineStyle = {
  color: colors.gray.inactive
}

const defaultAxisLine = {
  show: true,
  lineStyle: defaultLineStyle
}

const defaultXAxisTick = {
  inside: true,
  length: 5,
  lineStyle: {
    shadowColor: colors.gray.inactive,
    shadowOffsetY: 5,
    shadowOffsetX: 0,
    shadowBlur: 0
  }
}

export default {
  XYChartController,
  tooltipValueFormatter
}
