import c0 from './layout/notification_component_controller';
import c1 from './common/tabs_component_controller';
import c2 from './common/carousel_component_controller';
import c3 from './common/map_component_controller';
import c4 from './dashboard/offers/chart_component_controller';
import c5 from './chart/xy_chart_component_controller';
import c6 from './chart/sankey_chart_component_controller';
import c7 from './chart/base_chart_component_controller';
import c8 from './chart/line_chart_component_controller';
import c9 from './chart/scatter_chart_component_controller';
import c10 from './chart/bar_line_chart_component_controller';
import c11 from './chart/bar_chart_component_controller';
import c12 from './chart/gauge_chart_component_controller';
export const definitions = [
	{identifier: 'layout--notification-component', controllerConstructor: c0},
	{identifier: 'common--tabs-component', controllerConstructor: c1},
	{identifier: 'common--carousel-component', controllerConstructor: c2},
	{identifier: 'common--map-component', controllerConstructor: c3},
	{identifier: 'dashboard--offers--chart-component', controllerConstructor: c4},
	{identifier: 'chart--xy-chart-component', controllerConstructor: c5},
	{identifier: 'chart--sankey-chart-component', controllerConstructor: c6},
	{identifier: 'chart--base-chart-component', controllerConstructor: c7},
	{identifier: 'chart--line-chart-component', controllerConstructor: c8},
	{identifier: 'chart--scatter-chart-component', controllerConstructor: c9},
	{identifier: 'chart--bar-line-chart-component', controllerConstructor: c10},
	{identifier: 'chart--bar-chart-component', controllerConstructor: c11},
	{identifier: 'chart--gauge-chart-component', controllerConstructor: c12},
];
